<template>
  <a-spin :spinning="spanning" class="main-spinning" size="large">
    <div class="app-index-content">
      <AppCard :card="card" @cardClick="cardClick" v-if="!visible"/>
      <AppContentDrawer
          :card="card"
          v-else
          @closePanel="closePanel"
          :currentId.sync="curId"/>
    </div>
  </a-spin>
</template>
<script>
import {findCardByCatId} from "@/api/v2/appcenter";

export default ({
  components: {
    AppCard: () => import('@/components/Application/AppCard.vue'),
    AppContentDrawer: () => import('@/views/application/component/AppContentDrawer.vue')
  },
  data() {
    return {
      card: [],
      curId: '',
      visible: false,
      spanning: false
    }
  },
  watch: {
    '$route.params.id': {
      handler(current) {
        this.init(current)
      }
    }
  },
  methods: {
    closePanel() {
      this.visible = false
    },
    init(id) {
      this.spanning = true
      findCardByCatId(id).then(res => {
        this.card = res.data
      }).finally(() => {
        this.spanning = false
      })
    },
    cardClick(cur) {
      this.visible = true
      this.curId = cur.id
    },

  },
  mounted() {
    this.init(this.$route.params.id)
  }
})
</script>

<style scoped lang="scss">

.main-spinning {
  height: calc(100vh - 60px);
}

::v-deep.ant-spin-nested-loading > div > .ant-spin {
  max-height: 600px;
}
</style>